/*!
 * All material copyright ESRI, All Rights Reserved, unless otherwise specified.
 * See https://github.com/Esri/calcite-design-system/blob/dev/LICENSE.md for details.
 * v2.13.2
 */
import { Build } from '@stencil/core/internal/client';
const isBrowser = () => Build.isBrowser && typeof navigator !== "undefined" && typeof window !== "undefined" && typeof location !== "undefined" && typeof document !== "undefined" && window.location === location && window.document === document;
function getUserAgentData() {
  return navigator.userAgentData;
}
function getUserAgentString() {
  if (!isBrowser()) {
    return "";
  }
  const uaData = getUserAgentData();
  return uaData?.brands ? uaData.brands.map(({
    brand,
    version
  }) => `${brand}/${version}`).join(" ") : navigator.userAgent;
}
export { getUserAgentString as g, isBrowser as i };

